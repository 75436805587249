import React, {
  useRef,
  useEffect,
  useContext,
  useState,
  ReactElement,
} from 'react';
import styled, {StyledComponentProps, ThemeContext} from 'styled-components';
import Img, {FluidObject} from 'gatsby-image';
import {AmarisTheme} from '@hoffman/shared/src/theme';
import {useWindowSize} from '@hzdg/windowsize-monitor';

const spacing = (index: number) => (props: {theme: AmarisTheme}) =>
  `${props.theme.spaces[index]}px`;

const Video = styled.video`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  object-position: center center;
`;

const OuterWrapper = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

const InnerWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  @media (min-width: ${({theme}) => theme.breakpx[0] + 1}px) {
    transition-property: top, right, bottom, left;
    transition-delay: 4s;
    transition-duration: 1.5s;
    transition-timing-function: cubic-bezier();

    &.loaded {
      top: ${spacing(9)};
      right: ${spacing(7)};
      left: ${spacing(7)};
    }
  }
`;

const HeaderImg = styled(Img).attrs({
  // this overrides gatsby-img's inline style clobbering
  style: {position: 'absolute'},
})`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  object-position: center center;

  @media (max-width: ${({theme}) => theme.breakpoints[1]}) {
    display: none;
  }
`;

const MobileImg = styled(Img).attrs({
  // this overrides gatsby-img's inline style clobbering
  style: {position: 'absolute'},
})`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  object-position: center center;
  display: none;

  @media (max-width: ${({theme}) => theme.breakpoints[1]}) {
    display: inherit;
  }
`;

export interface HomeHeroHeaderProps {
  mobileImg: FluidObject;
  videoSrc: string;
  onShrink?: () => void;
}

const HomeHeroHeader = (props: HomeHeroHeaderProps) => {
  // const videoRef = useRef<HTMLVideoElement>(null);
  const [heavy, setHeavy] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const theme = useContext(ThemeContext) as AmarisTheme;
  const breakpx = theme.breakpx[0];
  const breakpoint = `${breakpx}px`;

  useWindowSize((windowSize) => {
    if (windowSize.width > breakpx) {
      setHeavy(true);
    } else {
      // Shows mobile menu on homepage
      // when not rendering video
      if (props.onShrink) setTimeout(props.onShrink, 600);
    }
  });

  return (
    <OuterWrapper>
      <InnerWrapper className={loaded ? 'loaded' : ''}>
        <HeaderImg fluid={props.headerImg} />
        <MobileImg fluid={props.mobileImg} />
        {heavy && (
          <Video
            {...{
              muted: true,
              src: props.videoSrc,
              autoPlay: true,
              onCanPlayThrough: () => {
                setLoaded(true);
                if (props.onShrink) {
                  setTimeout(props.onShrink, 4600);
                }
              },
            }}
          />
        )}
      </InnerWrapper>
    </OuterWrapper>
  );
};

export default HomeHeroHeader;
