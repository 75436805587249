import {graphql, useStaticQuery} from 'gatsby';
import React, {useRef, PropsWithChildren} from 'react';
import videoSrc from '../media/header-intro.mp4';
import Img, {FluidObject, GatsbyImageFluidProps} from 'gatsby-image';
import HomeHeroHeader from '@hoffman/shared/src/components/HomeHeroHeader';

interface propsHomeHeroBlock {
  onShrink?: () => void;
}

const HomeHeroBlock = (props: propsHomeHeroBlock) => {
  const data = useStaticQuery<GatsbyTypes.homeHeroBlockQuery>(graphql`
    query homeHeroBlock {
      headerImg: file(base: {eq: "header-intro.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mobileImg: file(base: {eq: "header-intro-mobile.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <HomeHeroHeader
      {...{
        videoSrc,
        headerImg: data.headerImg?.childImageSharp?.fluid as FluidObject,
        mobileImg: data.mobileImg?.childImageSharp?.fluid as FluidObject,
        onShrink: props.onShrink,
      }}
    />
  );
};

export default HomeHeroBlock;
