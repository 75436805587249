import React, {useContext} from 'react';
import {ThemeContext} from 'styled-components';
import {useStaticQuery, graphql} from 'gatsby';
import ContentPageSection from '@hoffman/shared/src/components/page-sections/ContentPageSection';

const HomeSecondaryContentBlock = (props) => {
  const {dataJson} = useStaticQuery<
    GatsbyTypes.HomeSecondaryContentBlockQuery
  >(graphql`
    query HomeSecondaryContentBlock {
      dataJson(slug: {eq: "home"}) {
        slug
        panels(name: "homeSecondaryContentPanel") {
          name
          models {
            type
            fields {
              text
              url
              newTab
            }
          }
        }
      }
    }
  `);
  const items = dataJson.panels[0].models.reduce(
    (acc: object, m: {type: string; fields: any}) => {
      acc[m.type] = m.fields;
      return acc;
    },
    {},
  );
  const theme = useContext(ThemeContext);

  return (
    <ContentPageSection
      bodyCopyPosition="center"
      blockWidth="450px"
      headlineSize={`${theme.fontSizes[5]}px`}
      headlineText={items.headline?.text}
      copySize={`${theme.fontSizes[2]}px`}
      copyText={items.copy?.text}
      rightPosition={true}
      topPosition={false}
      cta={true}
      ctaColor={theme.colors.text}
      ctaLink={items.link.url}
      ctaText={items.link.text}
      verticalText={items.verticalTag?.text}
      verticalColor={theme.colors.text}
    />
  );
};

export default HomeSecondaryContentBlock;
