import React, {useState} from 'react';
import {Link} from 'gatsby';
import BaseLayout from '@hoffman/shared/src/components/BaseLayout';
import SEO from '../../../../packages/docs/src/components/seo';
import Image from '@hoffman/shared/src/components/Image';
import Meta from '@hoffman/shared/src/components/Meta';
import NavbarBlock from '@hoffman/amaris/src/blocks/NavbarBlock';
import HomeHeroBlock from '~blocks/HomeHeroBlock';
import HomeIntroContentBlock from '~blocks/HomeIntroContentBlock';
import HomeImageExpandBlock from '~blocks/HomeImageExpandBlock';
import HomeSecondaryContentBlock from '~blocks/HomeSecondaryContentBlock';
import HomeImageBlock from '~blocks/HomeImageBlock';
import HomeTertiaryContentBlock from '~blocks/HomeTertiaryContentBlock';
import HomeCTABannerBlock from '~blocks/HomeCTABannerBlock';
import FooterBlock from '~blocks/FooterBlock';
import Section from '@hoffman/shared/src/components/Section';
import SectionBackgroundGroup from '@hoffman/shared/src/components/SectionBackgroundGroup';
import SectionPadding from '@hoffman/shared/src/components/SectionPadding';
import {useStaticQuery, graphql} from 'gatsby';

const IndexPage = () => {
  const {dataJson} = useStaticQuery<GatsbyTypes.HomePageQuery>(graphql`
    query HomePage {
      dataJson(slug: {eq: "home"}) {
        id
        seoDescription
        seoTitle
        bg {
          desktop {
            childImageSharp {
              fluid(maxWidth: 3000, quality: 95) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          mobile {
            childImageSharp {
              fluid(maxWidth: 3000, quality: 95) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  const bgImgs = {
    desktop: dataJson?.bg[0]?.desktop?.childImageSharp?.fluid,
    mobile: dataJson?.bg[0]?.mobile?.childImageSharp?.fluid,
  };

  const [loaded, setLoaded] = useState(false);
  return (
    <BaseLayout>
      <SEO title={dataJson.seoTitle} description={dataJson.seoDescription} />
      <NavbarBlock forceHidden={!loaded} isHome={true} />

      <HomeHeroBlock
        onShrink={() => {
          setLoaded(true);
        }}
      />

      <SectionBackgroundGroup>
        <SectionPadding>
          <HomeIntroContentBlock />
        </SectionPadding>
        <SectionPadding px="none" py="none">
          <HomeImageExpandBlock />
        </SectionPadding>
      </SectionBackgroundGroup>

      <Section themeName="DARK">
        <SectionBackgroundGroup
          bgImg={bgImgs.desktop}
          bgImgMobile={bgImgs.mobile}
        >
          <SectionPadding py="tall">
            <HomeSecondaryContentBlock />
          </SectionPadding>
          <SectionPadding px="none" py="none">
            <HomeImageBlock />
          </SectionPadding>
        </SectionBackgroundGroup>
      </Section>

      <SectionBackgroundGroup altBg={true}>
        <SectionPadding py="tall">
          <HomeTertiaryContentBlock />
        </SectionPadding>
      </SectionBackgroundGroup>

      <HomeCTABannerBlock />

      <FooterBlock />
    </BaseLayout>
  );
};

export default IndexPage;
